<template>
  <v-dialog
    v-model="toggle"
    scrollable
    transition="slide-y-reverse-transition"
    light
    :fullscreen="fullscreen"
  >
    <v-card class="terms">
      <v-btn
        absolute
        top
        right
        fab
        icon
        small
        class="mt-8 mr-n1"
        @click="toggleView"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
      <v-card-text 
        class="py-4 grey--text text--darken-3"
      >
        <h2 class="title text-h5 mt-6 grey--text text--darken-4">
          Termos e Condições
        </h2>
        <h2 class="text-overline text--secondary mb-6">
          Aplicativo GO Mobees
        </h2>
        <p>
          Estes Termos e Condições regem o Programa de Motoristas Mobees (“<span style="text-decoration:underline;">Programa</span>”), disponibilizado pela <strong>MOBEES ON WHEELS BRASIL LTDA.</strong>, sociedade com sede na Ladeira da Glória, nº 26, Bloco 3, 2º Pavimento, Glória, no Município do Rio de Janeiro, Estado do Rio de Janeiro, CEP 22.211-120, inscrita no CNPJ/ME sob o nº 35.711.113/0001-80 (“<span style="text-decoration:underline;">Mobees</span>”), e contratado pelo(a) motorista de táxi, devidamente regularizado(a) para o exercício da profissão, ou motorista de transporte remunerado privado de passageiros ou entregas, pessoa física, previamente aprovado(a) pela Mobees, que se encontra devidamente qualificado(a) no Termo de Adesão assinado eletronicamente nesta data, na página eletrônica da Mobees na rede mundial de computadores (“<span style="text-decoration:underline;">Contratante</span>”).
        </p>
        <p>
          O Aplicativo foi concebido para facilitar a gestão e exposição de conteúdo publicitário dos Clientes ("Clientes"), vinculadas às impressões contratadas em acordos comerciais com a Mobees.
        </p>
        <p>
          A MOBEES RESERVA PARA SI O DIREITO DE REALIZAR ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES NESTES TERMOS DE USO, A QUALQUER MOMENTO, SEM A NECESSIDADE DE AVISO PRÉVIO AO USUÁRIO. EM CASO DE ALTERAÇÕES DESTES TERMOS DE USO QUE RESTRINJAM DIREITOS DO USUÁRIO, A MOBEES PODERÁ COMUNICAR TAL ALTERAÇÃO AO USUÁRIO, AO SEU EXCLUSIVO CRITÉRIO, POR QUALQUER MEIO DE COMUNICAÇÃO DISPONÍVEL, SEJA FÍSICO OU ELETRÔNICO. TODAVIA, ESTA LIBERALIDADE NÃO AFASTA A RESPONSABILIDADE DO USUÁRIO DE VERIFICAR PERIODICAMENTE EVENTUAIS MODIFICAÇÕES DESTES TERMOS DE USO, BEM COMO NÃO AFETA O DIREITO DO USUÁRIO DE SE DESLIGAR DO APLICATIVO A QUALQUER MOMENTO.
        </p>
        <p>
          CASO O USUÁRIO NÃO CONCORDE COM ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES FUTURAS DESTES TERMOS DE USO, DEVERÁ CANCELAR PRONTAMENTE A SUA PARTICIPAÇÃO NO APLICATIVO E RESTITUIR A POSSE DOS EQUIPAMENTOS PARA A MOBEES, NA FORMA PREVISTA NESTES TERMOS DE USO. O FATO DE O USUÁRIO CONTINUAR A UTILIZAR OS EQUIPAMENTOS APÓS A DIVULGAÇÃO OU COMUNICAÇÃO DE UMA MODIFICAÇÃO DOS TERMOS DE USO REPRESENTA O SEU CONSENTIMENTO EM MANTER-SE A ESTES TERMOS DE USO, TAL COMO ALTERADOS.
        </p>
        <p>
          A MOBEES PODERÁ IMEDIATAMENTE ENCERRAR O APLICATIVO EM RELAÇÃO AO USUÁRIO OU DE MODO GERAL, NO TODO OU EM PARTE, A QUALQUER MOMENTO E POR QUALQUER MOTIVO, DE MODO QUE NÃO HAVERÁ QUALQUER GARANTIA DE CONTINUIDADE OU PERÍODO MÍNIMO DE DURAÇÃO NO APLICATIVO.
        </p>
        <p>
          AS PARTES ACEITAM E RECONHECEM COMO PLENAMENTE VÁLIDA E EFICAZ QUALQUER FORMA DE CELEBRAÇÃO DO TERMO DE ADESÃO E DESTES TERMOS DE USO, BEM COMO DE SUAS ALTERAÇÕES FUTURAS, POR MEIOS ELETRÔNICOS, INDEPENDENTEMENTE DA PRESENÇA DE TESTEMUNHAS, COM O USO DE SISTEMAS DE ASSINATURA ELETRÔNICA, QUE NÃO UTILIZAM UM CERTIFICADO DIGITAL ICP-BRASIL, NOS QUAIS A COMPROVAÇÃO DA ASSINATURA É FEITA POR MEIO DE EVIDÊNCIAS COLETADAS JUNTO ÀS PARTES SIGNATÁRIAS, NOS TERMOS DO ARTIGO 10, § 2º, DA MEDIDA PROVISÓRIA Nº 2200-2.
        </p>

        <ol>
          <li>
            <strong>CONSIDERAÇÕES PRELIMINARES</strong>  

            <ol>
              <li>
                O Aplicativo é disponibilizado ao Cliente, levando em consideração que:

                <ol type="i">
                  <li>
                    a Mobees é proprietária de equipamentos eletrônicos, hardwares, programas, aplicativos, redes e sistemas informatizados, destinados à exposição de conteúdo publicitário desenvolvido pelos Clientes nas cidades;
                  </li>

                  <li>
                    os anúncios são divididos em indicativos, publicitários, culturais e de utilidade pública. Os anúncios indicativos têm o objetivo de informar a atividade que é exercida pela Mobees. Anúncios publicitários são aqueles nos quais a mensagem oferece comercialmente um serviço, um produto ou uma comunicação institucional, sendo pagos pelos Clientes Mobees. Os anúncios culturais divulgam os eventos que estão ocorrendo no município. Anúncios de utilidade pública divulgam informações úteis dos órgãos oficiais. Os anúncios culturais e de utilidade pública serão na maioria das vezes pro bono, salvo caso em que sejam classificados como publicitários;
                  </li>

                  <li>
                    A Mobees se reserva ao direito de vetar e/ou remover conteúdos publicitários impróprios, ofensivos ou que infringem a legislação.
                  </li>
                </ol>
              </li>

              <li>
                Declaram as Partes que estes TERMOS DE USO são firmados nos limites de sua função social e pelos princípios da lealdade e boa-fé objetiva. Declaram, ainda, o seu expresso consentimento quanto às cláusulas e condições do Aplicativo e que não implicam em lesão de qualquer direito, tal qual previsto no artigo 157 da Lei nº 10.406, de 10 de janeiro de 2002, conforme alterada (Código Civil Brasileiro).
              </li>
            </ol>
          </li>

          <li>
            <strong>OBJETO</strong> 

            <ol>
              <li>
                Estes TERMOS DE USO e quaisquer outros documentos acessórios relacionados à participação do Usuário no Aplicativo compõem um contrato vinculativo, firmado entre a Mobees e o Usuário que engloba a cessão de espaço nos veículos dos Parceiros para veiculação de conteúdo publicitário desenvolvido pelos Clientes Mobees.
              </li>
            </ol>
          </li>

          <li>
            <strong>CADASTRO</strong>

            <ol>
              <li>
                Neste ato, o Usuário declara e garante à Mobees, como condições essenciais para a sua adesão ao Aplicativo, que:
                <ol>
                  <li>
                    é indivíduo maior de 21 (vinte e um) anos de idade, plenamente capaz para a assunção das obrigações previstas nestes TERMOS DE USO, bem como para o gozo de seus direitos civis, em geral;
                  </li>

                  <li>
                    reconhece que não tem e não terá poder ou autoridade para incorrer em qualquer dívida, obrigação ou responsabilidade em nome da Mobees;
                  </li>

                  <li>
                    entende e concorda que o Usuário determinará o método, detalhes e meios de desempenho das suas atividades profissionais que guardam relação com o Aplicativo, de modo que a Mobees não tem o direito de, e não irá, controlar a maneira ou determinar o método de desempenho das atividades profissionais do Usuário, incluindo a quantidade de tempo que o Usuário despende em suas  atividades;
                  </li>

                  <li>
                    leu e compreendeu estes TERMOS DE USO, esclareceu quaisquer dúvidas surgidas em relação às condições aqui estabelecidas e, feito isso, concordou em assumir todas as obrigações aqui previstas; e
                  </li>

                  <li>
                    seu registro e participação no Aplicativo estão em conformidade com todas e quaisquer leis, regulamentos, decisões judiciais, contratos e outros acordos aplicáveis, dos quais o Usuário seja parte ou aos quais o Usuário esteja sujeito, conforme o caso.
                  </li>
                </ol>
              </li>

              <li>
                Caso o Usuário deixe de cumprir os Requisitos, a qualquer momento, este se tornará inelegível para o Aplicativo, pelo que estes TERMOS DE USO serão automaticamente rescindidos. 
              </li>

              <li>
                Durante todo o período de sua participação no Aplicativo, o Usuário deverá manter atualizados os dados cadastrais solicitados pelo Aplicativo.
              </li>
              
              <li>
                Quaisquer notificações do Usuário à Mobees relacionadas ao Aplicativo deverão ser (i) enviadas por correio eletrônico (e-mail), a partir do endereço eletrônico do Usuário informado no momento de Cadastro no Aplicativo, para o endereço da Mobees: contato@mobees.com.br; ou (ii) enviadas por meio do canal de comunicações disponível na página eletrônica da Mobees na internet: http://www.mobees.com.br (“Canais de Comunicação”).
              </li>
            </ol>
          </li>

          <li>
            <strong>PRAZO E RESCISÃO</strong> 

            <ol>
              <li>
                Estes Termos e Condições são firmados por prazo indeterminado e entram em vigor na data da assinatura do Termo de Adesão. A extinção destes Termos e Condições ocorrerá pela vontade de qualquer das Partes, a qualquer momento, por qualquer motivo ou sem motivo, com ou sem aviso prévio, por falta imputável a qualquer das Partes ou, ainda, por motivos de força maior, não imputáveis a qualquer das Partes, mediante o envio de simples notificação de Parte a Parte. A MOBEES RESERVA PARA SI O DIREITO DE RESILIR UNILATERALMENTE ESTES TERMOS E CONDIÇÕES, A QUALQUER MOMENTO, DE FORMA IMOTIVADA, MEDIANTE O ENVIO DE SIMPLES NOTIFICAÇÃO AO CONTRATANTE; NESTE CASO, O CONTRATANTE NÃO FARÁ JUS AO RECEBIMENTO DE QUALQUER INDENIZAÇÃO E DEVERÁ CUMPRIR O DISPOSTO NO ITEM 8.3.1, ABAIXO. 
              </li>
              
              <li>
                Estes Termos e Condições serão rescindidos e extintos automaticamente, independentemente do envio de aviso ou notificação de Parte a Parte, em casos de (i) violação das obrigações ora assumidas, por parte do Contratante; (ii) falsidade, imprecisão ou incorreção das declarações prestadas pelo Contratante, nos termos aqui previstos, ou das informações fornecidas pelo Contratante no Termo de Adesão; (iii) descumprimento dos Requisitos do Programa, por parte do Contratante; ou (iv) falecimento, interdição, declaração de ausência ou qualquer condenação criminal do Contratante. 
              </li>
              
              <li>
                Caso o Contratante deseje encerrar a sua participação no Programa, este deverá notificar a Mobees sobre a sua decisão por intermédio de um dos Canais de Comunicação, na forma do item 3.4, acima. Igualmente, a Mobees, desejando encerrar o Programa em relação ao Contratante, deverá notificá-lo da extinção destes Termos e Condições (i) por correio eletrônico (<em>e-mail</em>), enviado para o endereço do Contratante indicado no Termo de Adesão; ou (ii) por escrito, mediante protocolo de recebimento, em um dos endereços do Contratante descritos no Termo de Adesão.  
                <ol>
                  <li>
                    O Contratante, após ter recebido uma notificação da Mobees sobre a extinção destes Termos e Condições ou, conforme o caso, após ter enviado uma notificação para a Mobees a respeito da extinção destes Termos e Condições deverá, no prazo de até 2 (dois) dias contados do recebimento/envio da notificação, agendar visita a uma das bases da Mobees para remoção dos Equipamentos, na forma do item 4.5 acima, sendo que o não comparecimento do Contratante na data agendada ensejará cobrança da multa moratória prevista no mesmo item. Caso o agendamento não seja realizado, por qualquer motivo, o Contratante deverá conduzir o seu veículo diretamente até uma das bases da Mobees para remoção dos Equipamentos, independentemente de agendamento, no prazo de até 5 (cinco) dias contados do recebimento/envio da notificação, na forma do item 4.5, acima. A não adoção de uma das medidas descritas neste item, pelo Contratante, no prazo aqui previsto, sujeitará o Contratante ao pagamento da multa moratória prevista no item 4.5, acima. 
                  </li>
                </ol>
              </li>

              <li>
                As Partes não farão jus a qualquer indenização ou compensação pela resilição unilateral destes Termos e Condições, independentemente do motivo ou de aviso prévio, exceto pela violação de quaisquer das previsões contidas nestes Termos e Condições, caso em que serão aplicáveis as penalidades e indenizações previstas neste Termos e Condições.
              </li>
            </ol>
          </li>

          <li>
            <strong>DISPOSIÇÕES GERAIS</strong> 
            <ol>
              <li>
                <span style="text-decoration:underline;">Relacionamento entre as Partes</span>. As Partes são e permanecerão sendo contratantes independentes, sem qualquer envolvimento ou relacionamento além do que se convenciona expressamente nestes TERMOS DE USO. Estes TERMOS DE USO e a participação do Usuário no Aplicativo não criam e nem criarão qualquer relacionamento ou vínculo de natureza societária, empregatícia e/ou econômica entre as Partes, sendo certo que o Usuário é livre para definir o tempo e o modo como conduzirá as suas atividades profissionais, notadamente os serviços de táxi ou de transporte remunerado privado de passageiros ou entregas ofertados por uma das organizações elencadas no Anexo I destes TERMOS DE USO, bem como o Usuário é livre para decidir sobre a manutenção ou não do contrato firmado com tais entidades. Fica consignado pelas Partes que a Mobees não possui frota de veículos ou softwares e sistemas voltados para intermediação e facilitação de serviços de transporte de passageiros ou entregas, fazendo somente o comodato dos Equipamentos para fins de exibição de conteúdo publicitário dos Clientes Mobees, de acordo com estes TERMOS DE USO.  
              </li>
              
              <li>
                <span style="text-decoration:underline;">Tolerância</span>. A tolerância de qualquer Parte a eventual conduta em desacordo com estes Termos e Condições, quer seja um descumprimento contratual ou não, representará mera liberalidade, não podendo ser invocada pela Parte inadimplente para respaldar conduta similar em outro momento. De igual modo, o não exercício de um direito não impedirá a Parte titular do direito de exercê-lo a qualquer tempo. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Alterações</span>. A MOBEES RESERVA PARA SI O DIREITO DE REALIZAR ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES NESTES TERMOS E CONDIÇÕES, A QUALQUER MOMENTO, SEM A NECESSIDADE DE AVISO PRÉVIO AO CONTRATANTE. EM CASO DE ALTERAÇÕES DESTES TERMOS E CONDIÇÕES QUE RESTRINJAM DIREITOS DO CONTRATANTE, A MOBEES PODERÁ COMUNICAR TAL ALTERAÇÃO AO CONTRATANTE, AO SEU EXCLUSIVO CRITÉRIO, POR QUALQUER MEIO DE COMUNICAÇÃO DISPONÍVEL, SEJA FÍSICO OU ELETRÔNICO. TODAVIA, ESTA LIBERALIDADE NÃO AFASTA A RESPONSABILIDADE DO CONTRATANTE DE VERIFICAR PERIODICAMENTE EVENTUAIS MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES.  
                <ol>
                  <li>
                    CASO O USUÁRIO NÃO CONCORDE COM ALTERAÇÕES, COMPLEMENTAÇÕES E ATUALIZAÇÕES FUTURAS DESTES TERMOS E CONDIÇÕES, DEVERÁ CANCELAR PRONTAMENTE A SUA PARTICIPAÇÃO NO PROGRAMA E RESTITUIR A POSSE DOS EQUIPAMENTOS PARA A MOBEES, NA FORMA PREVISTA NESTES TERMOS E CONDIÇÕES. O FATO DE O USUÁRIOS CONTINUAR A UTILIZAR OS EQUIPAMENTOS APÓS A DIVULGAÇÃO OU COMUNICAÇÃO DE UMA MODIFICAÇÃO DOS TERMOS E CONDIÇÕES REPRESENTA O SEU CONSENTIMENTO EM MANTER-SE A ESTES TERMOS E CONDIÇÕES, TAL COMO ALTERADOS. 
                  </li>
                </ol>
              </li>

              <li>
                <span style="text-decoration:underline;">Acordo Integral</span>. Estes Termos e Condições (incluindo todos os seus anexos), juntamente com o Termo de Adesão e quaisquer outros acordos expressamente incorporados por referência a estes Termos e Condições, representam o entendimento e o acordo integral, completo e exclusivo entre o Usuário e a Mobees em relação à participação do Usuário no Programa. Estes Termos e Condições e o Termo de Adesão prevalecem sobre qualquer outro acordo, verbal ou escrito, previamente estabelecido. Estes Termos e Condições obrigam as Partes, os seus sucessores e cessionários autorizados. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Cessão</span>. Fica vedada, sendo nula de pleno direito, a cessão da posição contratual do Usuário sem prévia anuência, por escrito, da Mobees. Por outro lado, a Mobees poderá ceder estes Termos e Condições a qualquer momento, sem aviso prévio ou consentimento do Usuário. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Consentimento para Comunicações Eletrônicas</span>. Ao participar do Programa, o Usuário concorda em receber comunicações eletrônicas enviadas pela Mobees, inclusive para eventuais alterações destes Termos e Condições, conforme aqui estabelecido. O Usuário concorda, ainda, que quaisquer avisos, acordos, divulgações ou outras comunicações que lhe sejam enviados eletronicamente satisfarão todos os requisitos legais de comunicação, incluindo que essas comunicações sejam feitas por escrito, para todos os fins. 
              </li>
              
              <li>
                <span style="text-decoration:underline;">Legislação Aplicável</span>. Este Termos e Condições é regido exclusivamente pelas leis brasileiras.
              </li>
            </ol>
          </li>

          <li>
            <strong>FORO</strong> 
            <ol>
              <li>
                Fica eleito o foro da Comarca do Rio de Janeiro, Estado do Rio de Janeiro, para solucionar uma disputa entre as Partes.
              </li>
            </ol>
          </li>
        </ol>

        <h3 class="subtitle-1">
          ANEXO I
        </h3>
        <h4 class="subtitle-2 mb-4">
          Descrição dos Equipamentos
        </h4>

        <p>
          Layout Externo do Equipamento
          <img
            class="mt-4"
            src="/img/tos-specs.png"
            width="100%"
            alt="Ilustração do Equipamento"
          >
        </p>

        <p>
          Lista de componentes:
        </p>
        <ul class="mb-8">
          <li>12 telas de led p3.33</li>
          <li>1 antena GPS</li>
          <li>1 gabinete preto de alumínio</li>
          <li>2 antenas para adaptador de wifi</li>
          <li>2 telas de policarbonato</li>
          <li>1 sensor de ajuste automático de brilho</li>
          <li>3 fontes de energia</li>
          <li>1 mini modem</li>
          <li>1 unidade de processamento</li>
          <li>cabos e fios elétricos e de dados</li>
          <li>1 placa de vídeo</li>
          <li>1 rack para carro</li>
        </ul>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="grey"
          text
          @click="toggleView"
        >
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click="toggleView(true)"
        >
          Li e Aceito
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 90% !important;
  }

  .terms {
    border-radius: 0 !important;
  }

  .terms ol { 
    padding: 1rem 0 0;
    list-style-position: outside;
    counter-reset: item;
  }
  .terms ol li { 
    display: inline-block;
    padding-bottom: 1rem;
  }
  .terms ol>li:before {
    font-weight: 700;
    content: counters(item, ".") ". ";
    counter-increment: item;
  }
  .terms ol ul {
    padding: 1rem 0 0 1rem;
    list-style-position: outside;
    list-style-type: square !important;
  }
  .terms ol ul>li:before {
    content: '■ ';
  }

</style>

<script>
  // Utilities
  import {
    mdiClose
  } from '@mdi/js'

  export default {
    name: 'Tos',

    props: {
      toggle: {
        type: Boolean,
        default: false
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        mdiClose
      },
    }),

    methods: {
      toggleView (accept) {
        this.$emit('toggle', accept)
      }
    },
  }
</script>
